import React from "react";
import { Header } from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import Modal from "./components/Modal/Modal";
import "./App.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { store } from "./Store/store";
import { lazy, Suspense, Fragment } from "react";
import ErrorBoundary from "./components/UI/ErrorBoundary";
import MessageBox from "./components/MessageBox/MessageBox";
import AcceptMaintenanceAndQalification from "./pages/AcceptMaintenanceAndQalification/AcceptMaintenanceAndQalification";
import {AuthProvider} from "./Store/UserContext";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Store/AuthSettings";

const queryClient = new QueryClient();

const MainAppContent = lazy(() => import("./pages/app-content/MainAppContent"));
const FilterPage = lazy(() => import("./pages/FilterPage/FilterPage"));
const MakeAnAppointment = lazy(() =>
  import("./pages/lines-page/MakeAnAppointment")
);
const CalanderView = lazy(() =>
  import("./pages/CalanderView/CalanderView")
);
const RequestRoutingPage = lazy(() =>
  import("./pages/RequestRoutingPage/RequestRoutingPage")
);
const OpenWeek = lazy(() =>
  import("./pages/OpenWeek/OpenWeek")
);
const GeneralSettings = lazy(() =>
  import("./pages/GeneralSettings/GeneralSettings")
);
const Contact = lazy(() =>
  import("./pages/Contact/Contact")
);
const Qna = lazy(() =>
  import("./pages/Qna/Qna")
);

const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {


  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <BrowserRouter>
              <Header />
              <div id="content">
                <ErrorBoundary
                  fallback={
                    <Fragment>
                      <h1>Somthing went wrong!</h1>
                      <p>Try reloading the page.</p>
                    </Fragment>
                  }
                >
                  <Suspense
                    fallback={<MessageBox message="Loading.." msgType="2" />}
                  >
                    <Routes>
                      <Route path="" element={<MainAppContent />} />

                      <Route
                        path="signin-callback"
                        element={<MessageBox message="Loading.." msgType="2" />}
                      />

                      <Route path="FilterPage" element={<FilterPage />} />
                      <Route
                        path="MakeAnAppointment"
                        element={<MakeAnAppointment />}
                      />
                      <Route path="CalanderView" element={<CalanderView />} />
                      <Route
                        path="GeneralSettings"
                        element={<GeneralSettings />}
                      />
                      <Route
                        path="request/*"
                        element={<RequestRoutingPage />}
                      />
                      <Route path="OpenWeek" element={<OpenWeek />} />
                      <Route path="Contact" element={<Contact />} />
                      <Route path="QNA" element={<Qna />} />
                      <Route
                        path="AcceptMaintenanceAndQalification"
                        element={<AcceptMaintenanceAndQalification />}
                      />
                    </Routes>
                  </Suspense>
                </ErrorBoundary>
              </div>
              <Footer />
            </BrowserRouter>
          </Provider>
        </QueryClientProvider>
      </AuthProvider>
    </MsalProvider>
  );
}
