import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  baseUnits: [],
  requestTypesToUnit: [],
  //requestsToUser: {},
  //allAppointments: {},
  selectedRequestTypeCod: 0,
  selectedServiceUnit: 0,
  selectedRequestCod: 0,
  loading: false,
  hasError: false,
  errorMsg: "There was a problem fetching data",
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    GotBaseUnits: (state, action) => {
      state.loading = false;
      state.baseUnits = action.payload;
    },

    GotRequestTypesToUnit: (state, action) => {
      console.log(action);
      state.loading = false;
      state.requestTypesToUnit = action.payload.requestTypes;
    },

    // GotAllAppointments: (state, action) => {
    //   console.log(action);
    //   state.loading = false;
    //   state.allAppointments = action.payload;
    // },

    // GotRequestsToUser: (state, action) => {
    //   //console.log(action);
    //   state.loading = false;
    //   state.requestsToUser = action.payload;
    // },

    SetSelectedRequestType: (state, action) => {
      state.selectedRequestTypeCod = action.payload;
    },
    SetSelectedRequest: (state, action) => {
      console.log("curr selected - " + action.payload);
      state.selectedRequestCod = action.payload;
    },
    SetServiceUnit: (state, action) => {
      state.selectedServiceUnit = action.payload;
    },
    // SetAppointment: (state, action) => {
    //   state.allAppointments.openRequests.filter(
    //     (itm) => itm.codRequest === action.payload.codRequest
    //   )[0].status = "סגור";

    //   state.requestsToUser.userRequests.push(action.payload);
    //   console.log(
    //     "curr update - " + state.allAppointments.openRequests[0].status
    //   );
    // },
    GettingItems: (state) => {
      state.loading = true;
    },
    HasError: (state) => {
      state.loading = false;
      state.hasError = true;
      //state.errorMsg = action.payload;
    },


  },
});

export const {
  GotBaseUnits,
  GotRequestTypesToUnit,
  // GotAllAppointments,
  //GotRequestsToUser,
  SetSelectedRequestType,
  SetSelectedRequest,
  // SetAppointment,
  GettingItems,
  SetServiceUnit,
  HasError,
} = mainSlice.actions;

export default mainSlice.reducer;
