import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAppointments: {},
  loading: true,
  hasError: false,
  errorMsg: "There was a problem fetching data",
};

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    GotAllAppointments: (state, action) => {
      console.log(action);
      // and again... "Update ___ was seccessful"
      if(action.payload === 'Update appointment was successful')
        return;
      state.loading = false;
      state.allAppointments = action.payload;
    },

    SetAppointment: (state, action) => {
      const curr = state.allAppointments.openRequests.filter(
        (itm) => itm.codRequest === action.payload.codRequest
      )[0];
      curr.status = "סגור";
      // curr.registeredToID = action.payload.registeredToID;
      // curr.requestItems = action.payload.requestItems;
    },
    RevertAppointmentToOpen: (state, action) => {
      const curr = state.allAppointments.openRequests.filter(
        (itm) => itm.codRequest === action.payload.codRequest
      )[0];
      curr.status = "חדש";
    },
    closeForEditing: (state, action) => {
      const curr = state.allAppointments.openRequests.filter(
        (itm) => itm.codRequest === action.payload.codRequest
      )[0];
      curr.status = "בעריכה";
    },
    GettingItems: (state) => {
      state.loading = true;
    },
    HasError: (state) => {
      state.loading = false;
      state.hasError = true;
      //state.errorMsg = action.payload;
    },
  },
});

export const { GotAllAppointments, SetAppointment,RevertAppointmentToOpen,closeForEditing, GettingItems, HasError } =
  appointmentsSlice.actions;

export default appointmentsSlice.reducer;
