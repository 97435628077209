import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";

function NavItem(props){
    const navigate = useNavigate();

    const onClick = () => {
        if(typeof props.onClick === 'function') {
            props.onClick();
        }
        navigate(props.nav);
    }

    return(
        <button className="nav-item" onClick={onClick}>
            <img alt="nav-item-logo" src={`images/${props.children}.png`}></img>
            <h1>{props.name}</h1>
        </button>
    );
};

export default NavItem;