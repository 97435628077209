import React from "react";
import "./Footer.scss";
import NavItem from "./nav-item";
import useAuth from "../../Store/UserContext";
import { useDispatch } from "react-redux";
import { SetSelectedRequestType } from "../../slices/mainSlice";

// need to move to seperate file???
export const launchBeework = () => {
    window.location.href = 'https://cubical.prat.idf.il/';
}


function Footer(){

    const dispatch = useDispatch();
    const {logoutFunc} = useAuth();

    const dispatchToContact = () => {
        dispatch(SetSelectedRequestType(3))
    }

    return(
        <footer>
            <NavItem name="בית" nav="/">home</NavItem>
            <NavItem name="Beework" onClick={launchBeework}>honeycomb</NavItem>
            <NavItem name="צור קשר" onClick={dispatchToContact} nav="/Contact">whatsapp</NavItem>
            <NavItem name="התנתקות" onClick={logoutFunc}>logout</NavItem>
        </footer>
    );
};

export default Footer;