
// import { LogLevel } from "@azure/msal-browser";

export const authSettings = {
    domain: 'https://login.microsoftonline.com/78820852-55fa-450b-908d-45c0d911e76b/oauth2/v2.0/',
    clientId: 'f5e9fd2f-20c5-4d50-90d0-54ac07d3807e',
    authorizationParams: {
      redirect_uri: window.location.origin,
      scope: 'User.Read openid profile email',
      // audience: 'http://service-in-hand.dev.idf.il/',
    }
  };

  export const msalConfig = {
    auth: {
        clientId: "f5e9fd2f-20c5-4d50-90d0-54ac07d3807e",
        authority: "https://login.microsoftonline.com/78820852-55fa-450b-908d-45c0d911e76b",
        redirectUri: window.location.origin 
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};
