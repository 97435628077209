import React from "react";
import classes from "../CloseButton/CloseButton.module.scss";
import { useNavigate } from "react-router-dom";
const CloseButton = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <svg
        {...props}
        onClick={() => {
          props.onClick?.();
          if(props.nav) {
            navigate(props.nav);
          }
        }}
        className={`${props.className} ${classes.closeButton}`}
        xmlns="http://www.w3.org/2000/svg"
        opacity="30%"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
        fillRule="evenodd"
      >
        <path
          fillRule="evenodd"
          d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
        />
      </svg>
    </>
  );
};

export default CloseButton;
