import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { mainSliceSelector } from "./mainSlice";
import dayjs from "dayjs";

const initialState = {
  isFilter: false,
  dateFrom: "",
  dateTo: "",
  hours: "",
  day: [],
  filteredAppointments: [],
};

export function isDateInRange(dateStr, startDateStr, endDateStr) {
  // changed start date and end date from date string to dayjs object
  const date = dayjs(dateStr);
  const startDate = dayjs(startDateStr)
  const endDate = dayjs(endDateStr)
  return date >= startDate && date <= endDate;
}

export function isHourInRange(chosenHours, lineHour){
  console.log(chosenHours)
  let fromChosenHour = Number(chosenHours.split("-")[0].split(":")[0]);
  let toChosenHour = Number(chosenHours.split("-")[1].split(":")[0]);
  console.log(fromChosenHour)

  let lineHourNum = Number(lineHour.split(" ")[1].split(":")[0])


  if (fromChosenHour <= lineHourNum && lineHourNum < toChosenHour){
    return true;
  } else {
    return false;
  }
}

export function isDayInDays(chosenDaysArray, dateStr){
  const daysList = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
  const day = dayjs(dateStr);
  
  let newDay = String(daysList[day.day()]);

  if(chosenDaysArray.includes(newDay)){
    return true;
  } else {
    return false;
  }
}




export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    NewFilter: (state) => {
      console.log("blalfbrk");
      state.isFilter = true;
      // console.log(state.isFilter)
    },

    setHours: (state, action) => {
      console.log("Filtering hours");
      state.hours = action.payload;
      console.log(state.hours)
    },

    setDay: (state, action) => {
      console.log("Filtering day");
      state.day = action.payload;
      console.log(state.day)
    },

    setDateFrom: (state, action) => {
      console.log("Filtering dateFrom");
      state.dateFrom = action.payload;
      console.log(state.dateFrom)
    },

    setDateTo: (state, action) => {
      console.log("Filtering dateTo");
      state.dateTo = action.payload;
      console.log(state.dateTo)
    },

    // not in use anymore, filtering done now in MakeAnAppointment page.
    FilterAppointments: (state, {payload}, allAppointments) => {
      console.log("Filtered Appointments");
      console.log(payload)

      // if(!state.isFilter){
      //   state.isFilter = true;
      // }

      state.filteredAppointments = payload;
      console.log(state.filteredAppointments)
      
      if (state.dateFrom !== "" && state.dateTo !== ""){
        // let is = isDateInRange("05/04/23", "Mon Apr 03 2023 00:00:00 GMT-0700 (שעון מערב ארה״ב (קיץ))" , "Wed Apr 05 2023 00:00:00 GMT-0700 (שעון מערב ארה״ב (קיץ))")
        state.filteredAppointments = [...state.filteredAppointments].filter((item) => isDateInRange(item.requestDate.split(" ")[0], state.dateFrom, state.dateTo))
        console.log(state.filteredAppointments)

      }
      
      if (state.hours !== "" && state.hours !== "כל השעות"){
        state.filteredAppointments = [...state.filteredAppointments].filter((item) => isHourInRange(state.hours, item.requestDate))
        // isHourInRange("08:00-10:00", "09:00")
      }

      if (state.day.length !== 0){
        state.filteredAppointments = [...state.filteredAppointments].filter((item) => isDayInDays(state.day, item.requestDate))
        
      }

    },

    // remove specific appointment from the filtered appointments
    removeAppointmentFromFilter: (state, {payload}) => {
      state.filteredAppointments = [...state.filteredAppointments].filter((item) => (payload.codRequest !== item.codRequest));
    },

    resetFilters: (state, _action) => {
      for( const key in state ) {
        state[key] = initialState[key];
      }
    }

  }

});

export const {
  NewFilter,
  setHours,
  setDay,
  setDateFrom,
  setDateTo,
  removeAppointmentFromFilter,
  resetFilters
} = filterSlice.actions;

export default filterSlice.reducer;