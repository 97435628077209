import React from "react";
import "./TopType.scss";
import { useSelector } from "react-redux";

export default function TopType() {
  const { requestTypesToUnit, selectedRequestTypeCod } = useSelector(
    (state) => state.mainSlice
  );
  const selectedRequestType = requestTypesToUnit.filter(rt => (selectedRequestTypeCod  === rt.codRequestType))[0].requestType;
  return (
    // <div className="top-type-wrapper">
      <div className="top-type">
        <h3>{selectedRequestType}</h3>
      </div>
    // </div>
  );
}
