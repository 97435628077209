import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    loading: true,
    hasError: false,
    errorMsg: "There was a problem fetching data",
  };

export const itemsSlice = createSlice({
name: "items",
initialState,
reducers: {
    GotAllItems: (state, action) => {
        console.log("items", action.payload);
        state.loading = false;
        state.items = action.payload.items;
    },
    GettingItems: (state) => {
        state.loading = true;
    },
    HasError: (state) => {
        state.loading = false;
        state.hasError = true;
    },
},
});

export const {
GotAllItems,
GettingItems,
HasError,
} = itemsSlice.actions;

export default itemsSlice.reducer;
