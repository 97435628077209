import { createSlice } from "@reduxjs/toolkit";
import { editItem, editSpecificItem } from "../data/fetchFunctions";
import { useSelector } from "react-redux";

const initialState = {
  codUnit: 5,
  lineDuration: 120,
  hoursPerDay: {
    sunday: {
      fromHour: "00:00",
      toHour: "00:00"
    },
    monday: {
      fromHour: "00:00",
      toHour: "00:00"
    },
    tuesday: {
      fromHour: "00:00",
      toHour: "00:00"
    },
    wednesday: {
      fromHour: "00:00",
      toHour: "00:00"
    },
    thursday: {
      fromHour: "00:00",
      toHour: "00:00"
    }
  },
  breakHours: [],
  loading: true,
  hasError: false,
  errorMsg: "There was a problem fetching data",
};

export const generalSettingsSlice = createSlice({
  name: "generalSettings",
  initialState,
  reducers: {
    GotAllGeneralSettings: (state, action) => {
      console.log(action.payload);
      console.log(state.codUnit)
      console.log(action.payload.generalSettings.filter((itm) => itm.codUnit === state.codUnit)[0])
      state.lineDuration = action.payload.generalSettings.filter((itm) => itm.codUnit === state.codUnit)[0].lineDuration;
      state.hoursPerDay = action.payload.generalSettings.filter((itm) => itm.codUnit === state.codUnit)[0].hoursPerDay;
      state.breakHours = action.payload.generalSettings.filter((itm) => itm.codUnit === state.codUnit)[0].breakHours;
      state.loading = false;
    },

    setCodUnit: (state, action) => {
      state.codUnit = action.payload;
    },

    SetAllGeneralSettings: (state, action) => {
      console.log(action.payload);
      state.lineDuration = action.payload.lineDuration;
      state.hoursPerDay = action.payload.hoursPerDay;
      state.breakHours = action.payload.breakHours;

      // editItem("GetGeneralSettings", action.payload);
      editSpecificItem("generalSettings", {...action.payload, codUnit: state.codUnit}, "codUnit", state.codUnit);
    },

    // SetNewEvent: (state, action) => {

    //   if(action.payload.event.isAllDay) {
        
    //   }

    //   if (action.payload.action === 'ADD_EVENT') {
    //     state.generalSettings.generalSettings = [...state.generalSettings.generalSettings, action.payload.event];
    //   }
    //   else if (action.payload.action === 'EDIT_EVENT') {
    //     state.generalSettings.generalSettings = state.generalSettings.generalSettings.map(e => (
    //       e.id === action.payload.event.id ?
    //       action.payload.event
    //       :
    //       e
    //       ));
    //   }
    // },

    // UpdateUserRequest: (state, action) => {
    //   //state.requestsToUser.userRequests.push(action.payload);
    //   const reqIndex = state.requestsToUser.userRequests.findIndex(
    //     (b) => b.codRequest === action.payload.codRequest
    //   );
    //   state.requestsToUser.userRequests[reqIndex] = action.payload;
    // },
    GettingItems: (state) => {
      state.loading = false;     
    },
    HasError: (state) => {
      state.loading = false;
      state.hasError = true;
      //state.errorMsg = action.payload;
    },
  },
});

export const {
  GotAllGeneralSettings,
  SetAllGeneralSettings,
  GettingItems,
  setCodUnit,
  HasError,
} = generalSettingsSlice.actions;

export default generalSettingsSlice.reducer;
