import React from "react";
import "./LargeContainer.scss";

const LargeContainer = (props) => {
  return (
    <div
      className="large-container"
      onClick={() => {
        props.navFunc?.(props.cod);
      }}
    >
      <h3>{props.firstTitle}</h3>
      <h3>{props.secondTitle}</h3>
    </div>
  );
};

export default LargeContainer;
