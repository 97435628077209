import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
  loading: true,
  hasError: false,
  errorMsg: "There was a problem fetching data",
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    GotAllEvents: (state, action) => {
      if(action.payload === 'weekOpening Update was successfull'){
        console.log("Detected Week Opening REDUX")
        return;
      } else if (action.payload === 'There was a problem fetching data'){
        return;
      }
      state.loading = false;
      state.events = action.payload.weekOpenings;
      console.log("action payload events")
      console.log(action.payload)
    },

    SetNewEvent: (state, action) => {
      const {event, week} = action.payload;
      let weekOpening = state.events.filter(wo => wo.weekStartDate === week)[0];
      if (weekOpening === undefined) {
        weekOpening = {
          "weekStartDate": week,
          "events": []
        };
        state.events.push(weekOpening);
      }
      weekOpening.events = [...weekOpening.events, event];

    },

    ModifyEvent: (state, action) => {
      const {event, week} = action.payload;
      console.log(event)
      const weekOpening = state.events.filter(wo => wo.weekStartDate === week)[0];
      weekOpening.events = weekOpening.events.map(e => (
        e.event_id === event.event_id ?
        event
        :
        e
        ));
    },

    DeleteEvent: (state, action) => {
      const {event_id, week} = action.payload;
      console.log(event_id)

      let unitEvents = state.events
      const weekOpening = unitEvents.filter(wo => wo.weekStartDate === week)[0];
      weekOpening.events = weekOpening.events.filter(e => e.event_id !== event_id);
      console.log(weekOpening.events)

    },

    GettingItems: (state) => {
      state.loading = true;
    },
    HasError: (state) => {
      state.loading = false;
      state.hasError = true;
    },
  },
});

export const {
  GotAllEvents,
  SetNewEvent,
  ModifyEvent,
  DeleteEvent,
  GettingItems,
  HasError,
} = eventsSlice.actions;

export default eventsSlice.reducer;
