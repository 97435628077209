import React from "react";
import { useState } from "react";
import "../ExpendedLargeContainer/ExpendedLargeContainer.scss";
import { editItem } from "../../data/fetchFunctions"
import { useDispatch, useSelector } from "react-redux";
import useDispachFetches from "../../data/useDispachFetches";
import { GotRequestsToUser } from "../../slices/userRequestsSlice";
import dayjs from "dayjs";

// needs to move to seperate file
const tools = [
  'מחשב סודי',
  'מחשב סודי ביותר',
  'מקרן סודי/סודי ביותר',
  'כיתת מחשבים',
];

const ExpendedLargeContainer = (props) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const item = props.item;

  const {requestsToUser} = useSelector(state => state.userRequestsSlice)

  function handleCheckClicked(e){
    console.log(item);
    console.log(e.target.name);
    console.log(requestsToUser)
    
    // Deep copy of Redux element
    let newRequestsToUser = JSON.parse(JSON.stringify(requestsToUser));

    // Find the selected request Date and change him by the clicked correct optDate
    newRequestsToUser.userRequests.find((itm) => itm.codRequest === item.codRequest).requestDate = item[`optDate${e.target.name}`];

    //Edit Item in DB all chunk
    editItem("users", newRequestsToUser);
    dispatch(GotRequestsToUser(newRequestsToUser));
    
    }

  return (
    <div
      className="expended-large-container"
    >
      <div onClick={() => setIsOpen(!isOpen)}>{props.children}</div>
      {isOpen &&
      <div className="expended-large-container-data">
      {
        props.isMaintenance ?
        <>
          <h2><span className="expended-large-container-title">שם יחידה:</span> {item.unitName}</h2>
          <h2><span className="expended-large-container-title">מטרה:</span> {item.target}</h2>
          {item.targetAudience1 &&
          <h2>
            <span className="expended-large-container-title">אוכלוסיית יעד:</span> {item.targetAudience1},&nbsp;
            <span className="expended-large-container-title">כמות:</span> {item.amount1},&nbsp;
            <span className="expended-large-container-title">רמה נדרשת:</span> {item.level1}
          </h2>
          }
          {item.targetAudience2 &&
          <h2>
            <span className="expended-large-container-title">אוכלוסיית יעד:</span> {item.targetAudience2},&nbsp;
            <span className="expended-large-container-title">כמות:</span> {item.amount2},&nbsp;
            <span className="expended-large-container-title">רמה נדרשת:</span> {item.level2}
          </h2>
          }
          {item.targetAudience3 &&
          <h2>
            <span className="expended-large-container-title">אוכלוסיית יעד:</span> {item.targetAudience3},&nbsp;
            <span className="expended-large-container-title">כמות:</span> {item.amount3},&nbsp;
            <span className="expended-large-container-title">רמה נדרשת:</span> {item.level3}
          </h2>
          }
          <h2><span className="expended-large-container-title">אמצעים: </span>
          {(() => {
            const filteredData = item.toolsZ.map((exists, index) => (exists ? tools[index] : null)).filter((item) => item !== null);
            return filteredData.length !== 0 ? filteredData.join(', ') : 'אין';
          })()}
          </h2>
        </>
        :
        props.isQualification &&
          <>
          <h2><span className="expended-large-container-title">איש קשר:</span> {item.contactMan}</h2>
          <h2><span className="expended-large-container-title">טלפון:</span> {item.telephone}</h2>
          <h2><span className="expended-large-container-title">תיאור תקלות:</span> {item.malfunctionDesc}</h2>
          <h2><span className="expended-large-container-title">כלים:</span> {item.toolsZ}</h2>
          </>
      }
        <hr />
        <div>
          <h2 className="expended-large-container-date-select">
            <span>
              <span className="expended-large-container-title">תאריך בחירה ראשון:</span>
              &nbsp;{dayjs(item.optDate1).format('DD/MM/YYYY')}
            </span>
            <button onClick={handleCheckClicked} name="1">
              <img alt="check-mark-button" className="check-mark-button" src="images/check-mark-button.png" />
              </button>
          </h2>
        </div>
        <div>
          <h2 className="expended-large-container-date-select">
            <span>
              <span className="expended-large-container-title">תאריך בחירה שני:</span>
              &nbsp;{dayjs(item.optDate2).format('DD/MM/YYYY')}
            </span>
            <button onClick={handleCheckClicked} name="2">
              <img alt="check-mark-button" className="check-mark-button" src="images/check-mark-button.png" />
            </button>
          </h2>
        </div>
        <div>
          <h2 className="expended-large-container-date-select">
            <span>
              <span className="expended-large-container-title">תאריך בחירה שלישי:</span>
              &nbsp;{dayjs(item.optDate3).format('DD/MM/YYYY')}
            </span>
            <button onClick={handleCheckClicked} name="3">
              <img alt="check-mark-button" className="check-mark-button" src="images/check-mark-button.png" />
            </button>
          </h2>
        </div>

      </div>

       }
    </div>
  );
};

export default ExpendedLargeContainer;
