import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { GotBaseUnits } from "../slices/mainSlice";
import {
  GotRequestTypesToUnit,

  //  GotAllAppointments,
} from "../slices/mainSlice";
import { GotAllAppointments } from "../slices/appointmentsSlice";
import { GotRequestsToUser } from "../slices/userRequestsSlice";
import { GotAllEvents } from "../slices/eventsSlice";
import { GotAllItems } from "../slices/itemsSlice";
import useDispachFetches from "./useDispachFetches";
import { useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../Store/AuthSettings";

//const BackServerUrl = "http://localhost:8000/api";
const BackServerUrl = "https:/";
const REFETCH_INTERVAL_MS = 1000;

const pca = new PublicClientApplication(msalConfig);
async function getAccessToken() {
  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    const request = {
      scopes: ["api://59f38cff-dfb7-4375-af5b-80933dd14e88/Data.Read"],
      // roles: ["Workspace.read"],
      account: accounts[0],
    };
    const accessToken = await pca
      .acquireTokenSilent(request)
      .then((response) => {
        return response.accessToken;
      })
      .catch((error) => {
        // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
        console.log(error);
        return null;
      });

    return accessToken;
  }

  return null;
}

export async function getData(url) {
  return await fetch(BackServerUrl + url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      // roles: ["Workspace.read"],
    },
  }).then((resp) => {
    if (!resp.ok) {
      throw Error("There was a problem fetching data.");
    }

    return resp.json();
  });
}

export async function createItem(url, item) {
  return await fetch(BackServerUrl + "/" + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
      // roles: ["Workspace.read"],
    },
    body: JSON.stringify(item),
  }).then((r) => {
    if (!r.ok) {
      throw new Error("There was a problem creating the item!");
    }
    return r.json();
  });
}

export async function editItem(url, item) {
  return await fetch(BackServerUrl + "/" + url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: JSON.stringify(item),
  })
    .then((r) => {
      if (!r.ok) {
        console.log("error in edit item");
        throw new Error("There was a problem updating the item!");
      }
      console.log("yalla");
      console.log(r);
      return r.json();
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function editSpecificItem(url, item, detectName, detectValue) {
  return await fetch(BackServerUrl + "/" + url + `/${detectValue}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: JSON.stringify(item),
  }).then((r) => {
    if (!r.ok) {
      throw new Error("There was a problem updating the item!");
    }
    return r.json();
  });
}

export async function deleteItem(url) {
  return await fetch(BackServerUrl + "/" + url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  }).then((r) => {
    if (!r.ok) {
      throw new Error("There was a problem deleting the item!");
    }
    return r.json();
  });
}

// not in use
export async function getAppointmentsForUnit(url, cod_unit) {
  return await fetch(BackServerUrl + url + "/" + String(cod_unit), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  }).then((resp) => {
    if (!resp.ok) {
      throw Error("There was a problem fetching data.");
    }

    return resp.json();
  });
}

export function useQueryFetch(
  fetchUrl,
  dispachFunc,
  paramID,
  fetchFunction = getData
) {
  const respnse = useQuery(
    fetchUrl,
    async () => await fetchFunction("/" + fetchUrl),
    { suspense: true }
  );
  useDispachFetches(dispachFunc, dispachFunc, respnse);
}

export function useQueryFetchInterval(
  fetchUrl,
  dispachFunc,
  paramID,
  fetchFunction = getData
) {
  const respnse = useQuery(
    fetchUrl,
    async () => await fetchFunction("/" + fetchUrl),
    { suspense: true, refetchInterval: REFETCH_INTERVAL_MS }
  );
  useDispachFetches(dispachFunc, dispachFunc, respnse);
}

export function useUpdate(key) {
  const queryClient = useQueryClient();
  const mutation = useMutation(async (item) => await editItem(key, item), {
    onSuccess: (currOpenReq) => {
      console.log("use update succeeded");
      queryClient.invalidateQueries(key);
      const openReq = queryClient.getQueryData(key) || {};
      queryClient.setQueryData(key, currOpenReq);
    },

    onError: (err) => {
      console.log(err);
    },
  });

  return {
    updateReq: mutation.mutate,
    isUpdatingReq: mutation.isLoading,
  };
}

export function useCreateRequest(key) {
  const queryClient = useQueryClient();
  const mutation = useMutation((item) => createItem(key, item), {
    onSuccess: (userRequest) => {
      queryClient.invalidateQueries(key);
      const requests = queryClient.getQueryData(key) || [];
      queryClient.setQueryData(key, [...requests, userRequest]);
    },
  });

  return {
    createUserRequest: mutation.mutate,
    isCreating: mutation.isLoading,
  };
}

// function updateCache(bookable, queryClient) {
//   // get all the bookables from the cache
//   const bookables = queryClient.getQueryData("bookables") || [];

//   // find the index in the cache of the bookable that's been edited
//   const bookableIndex = bookables.findIndex((b) => b.id === bookable.id);

//   // if found, replace the pre-edited version with the edited one
//   if (bookableIndex !== -1) {
//     bookables[bookableIndex] = bookable;
//     queryClient.setQueryData("bookables", bookables);
//   }
// }

export function useGetAllAppointments() {
  // const data =
  useQueryFetchInterval(
    "service-in-hand-be.dev.idf.il:8002/appointments",
    GotAllAppointments
  );
  //return data;
}

export function useGetBaseUnits() {
  //const data =
  // useQueryFetch("GetBaseUnits", GotBaseUnits);
  // return data;
}

export function useGetRequestTypesToUnit() {
  // const data =
  useQueryFetch(
    "service-in-hand-be.dev.idf.il:8003/requestTypes",
    GotRequestTypesToUnit
  );
  // return data;
}

export function useGetRequestsToUser(userID) {
  useQueryFetch(
    "service-in-hand-be.dev.idf.il:8001/users",
    GotRequestsToUser,
    undefined,
    (url) => createItem(url.substring(1), { username: userID })
  );
  //return data;
}

export function useGetAllEvents(codUnit) {
  //const data =
  useQueryFetch(
    `service-in-hand-be.dev.idf.il:8004/weekOpenings/${codUnit}`,
    GotAllEvents
  );
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("hi")
  //   getData(`/weekOpenings/${codUnit}`).then((response) =>{
  //     dispatch(GotAllEvents(response))

  //   } );
  //   // dispatch(GotAllEvents);
  // }, [codUnit])

  // useDispachFetches(GotAllEvents, GotAllEvents, response)

  //return data;
}

export function useGetAllItems() {
  //const data =

  useQueryFetch("items", GotAllItems);
  // useDispachFetches(GotAllItems, GotAllItems, {
  //   status: "success",
  //   data: {
  //     "items": [
  //       {
  //           "id": "457645",
  //           "itemDescription": "מ.ח 8765"
  //       },
  //       {
  //           "id": "457643",
  //           "itemDescription": "מ.ח 9765"
  //       },
  //       {
  //           "id": "457642",
  //           "itemDescription": "מ.ח 7765"
  //       }
  //     ]
  //   }
  // });

  //return data;
}

export async function getQNA() {
  // return await getData('/GetQNA');
  return [
    {
      id: 1,
      question: "איך אני קובע תור?",
      answer:
        "לוחצים על תיאום יום קבלה, בוחרים יחידה, ובוחרים בתאריך שמתאים לכם!",
    },
    {
      id: 2,
      question: "איך אני יודע שהתור שלי נקבע?",
      answer:
        "ברגע שאתם בוחרים תור ומזינים את הפריטים הוא נוצר בשבילכם, תוכלו תמיד לערוך את התור שלכם מהרשימה הנמצאת בדף הבית",
    },
    {
      id: 3,
      question: "איפה אני רואה תורים שכבר קבעתי?",
      answer:
        'כל התורים שקבעתם מופיעים ברשימת "ימי קבלה שקבעתי" המופיעה בדף הבית למטה.',
    },
    {
      id: 4,
      question: "איך אני יכול לבטל תור?",
      answer:
        'מדף הבית תוכלו לנווט בתורים שלכם, תלחצו על התור שתרצו לבטל ולחצו על "מחק יום קבלה". שימו לב שפעולה זו אינה הפיכה.',
    },
    {
      id: 5,
      question: "אין תורים בתאריכים שמתאימים בשבילי",
      answer:
        "התאריכים שרשומים בתיאום יום קבלה הם התאריכים שבהם היחידה פנויה לתת שירות, למקרים דחופים ניתן ליצור קשר.",
    },
    {
      id: 6,
      question: "אני לא מוצא את התור שלי ברשימת התורים",
      answer:
        "שימו לב שהתורים שלכם ממוינים לפי תאריך יום הקבלה, ולא לפי סדר יצירתם. לנוחות שלכם, אנחנו לא מציגים תורים שכבר התאריך שלהם עבר.",
    },
  ];
}
