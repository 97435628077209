import React from "react";
import classes from "../AcceptMaintenanceAndQalification/AcceptMaintenanceAndQalification.module.scss";
import TopType from "../../components/TopType/TopType";
import { LogisticRequestItemList } from "../../components/LogisticRequestItemList/LogisticRequestItemList";
import RequestsList from "../../components/RequestsList/RequestsList";
import { useSelector } from "react-redux";
import LargeContainer from "../../components/LargeContainer/LargeContainer";
import { useState } from "react";
import ExpendedLargeContainer from "../../components/ExpendedLargeContainer/ExpendedLargeContainer";
import RightType from "../../components/RightType/RightType";
import BlueButton from "../../components/BlueButton/BlueButton";
import dayjs from "dayjs";

const AcceptMaintenanceAndQalification = () => {
  // Get all requests
  const { requestsToUser } = useSelector((state) => state.userRequestsSlice);
  console.log(requestsToUser.userRequests);


  // Filter requests to Maintenance requests
  const filteredMaintenanceRequests = requestsToUser.userRequests.filter(
    (item) =>
      item.codRequestType === 4 &&
      item.status === "חדש"
  );
  console.log(filteredMaintenanceRequests);

  // Filter requests to Qualification requests
  const filteredQualificationRequests = requestsToUser.userRequests.filter(
    (item) =>
      item.codRequestType === 2 &&
      item.status === "חדש"
  );
  console.log(filteredQualificationRequests);


  return (
    <div className={classes['all-page']}>
      <TopType />
      <RightType title="אישור צוותי הטמעה" />
      <div className={classes.wrapper}>
        {filteredMaintenanceRequests.map((item, i) => (
          <ExpendedLargeContainer key={i} item={item} isMaintenance={true}>
            <LargeContainer
              key={i}
              cod={item.codRequest}
              firstTitle={item.HandleUnit}
              secondTitle={item.requestDate === '' ? 'יש לבחור תאריך' : dayjs(item.requestDate).format('DD/MM/YYYY')}
            />
          </ExpendedLargeContainer>
        ))}
      </div>
      <RightType title="אישור צוותי כשירות" />
      <div className={classes.wrapper}>
        {filteredQualificationRequests.map((item, i) => (
          <ExpendedLargeContainer key={i} item={item} isQualification={true}>
            <LargeContainer
              cod={item.codRequest}
              firstTitle={item.HandleUnit}
              secondTitle={item.requestDate === '' ? 'יש לבחור תאריך' : dayjs(item.requestDate).format('DD/MM/YYYY')}
            />
          </ExpendedLargeContainer>
        ))}
      </div>
      <BlueButton className={classes.BlueButton} name="חזרה" nav="/" />
    </div>
  );
};

export default AcceptMaintenanceAndQalification;
