import { createAuth0Client } from "@auth0/auth0-spa-js";
import React, { useContext, useEffect, useState } from "react";
import { authSettings } from "./AuthSettings";
import { redirect } from "react-router-dom";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Header } from "../components/header/Header";
import MessageBox from "../components/MessageBox/MessageBox";
import { createItem } from "../data/fetchFunctions";
import classes from "../components/BlueButton/BlueButton.module.scss";

const DEV_ADMIN = false; // true To show admin page

const Auth0Context = React.createContext({
  isAuthenticated: false,
  user: null,
  loginFunc: () => {},
  logoutFunc: () => {},
  loading: false,
});

const useAuth = () => React.useContext(Auth0Context);

export const AuthProvider = (props) => {
  useMsalAuthentication(InteractionType.Redirect);

  const msal = useMsal();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(
    null /* { 	
        userID: 5102422,
        username:"עדי דימטשטיין",
        tel: "-",
        email:"asasd@gmail.com",
        authGroup: 1,
        adminUnits: [5,6,7] ,
        userRequests:[],
    } */
  );
  const [UserChange, setUserChange] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(msal);
    if (msal.accounts.length > 0) {
      const account = msal.accounts[0];
      createItem("service-in-hand-be.dev.idf.il:8001/users", {
        username: account.username,
      }).then(({ authGroup, adminUnits }) => {
        const accountData = { authGroup, adminUnits };
        if (DEV_ADMIN) {
          setUser((prev) => ({
            userID: account.username,
            username: account.name,
            email: account.username,
            ...accountData,
            authGroup: 2,
            adminUnits: [5, 6, 7, 8],
          }));
        } else {
          setUser((prev) => ({
            userID: account.username,
            username: account.name,
            email: account.username,
            ...accountData,
          }));
        }
        setUserChange(true);
      });
    } else {
      setUser(null);
      setUserChange(true);
    }
  }, [msal]);

  useEffect(() => {
    if (UserChange) {
      setIsAuthenticated(user !== null);
      setLoading(msal.inProgress !== "none");
      setUserChange(false);
    }
  }, [UserChange]);

  const login = () => {
    //to do
  };

  const logout = () => {
    setLoading(true);
    msal.instance.logoutRedirect();
  };

  if (loading) {
    return (
      <>
        <Header />
        <MessageBox message="Loading.." msgType="2" />
      </>
    );
  }
  if (!isAuthenticated) {
    return (
      <>
        <Header />
        <div style={{ textAlign: "center" }}>
          <MessageBox
            message="יש להתחבר ל - My IDF כדי להשתמש באפליקציה"
            msgType="1"
          />
        </div>
        <button
          style={{ margin: "0 auto" }}
          className={classes["blue-button"]}
          onClick={() => {
            msal.instance.loginRedirect();
          }}
        >
          <h4 className={classes["blue-button__h4"]}>להתחברות</h4>
        </button>
      </>
    );
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated: isAuthenticated,
        user: user,
        loginFunc: login,
        logoutFunc: logout,
        loading: loading,
      }}
    >
      {props.children}
    </Auth0Context.Provider>
  );
};

export default useAuth;
