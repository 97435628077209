import React from "react";
import "./RightType.scss";
import { useSelector } from "react-redux";

export default function RightType(props) {
  const { requestTypesToUnit, selectedServiceUnit, selectedRequestTypeCod } =
    useSelector((state) => state.mainSlice);

    console.log(requestTypesToUnit);
    const displayedTitle = props.title ?
    props.title
    : 
    requestTypesToUnit
    .filter(rt => (selectedRequestTypeCod  === rt.codRequestType))
    [0]
    .units
    .filter(
      (e) => e.codUnit === selectedServiceUnit
      )
    [0]
    .name;

  return (
    <div className="right-type">
      {props.filter && <h3>אפשרויות סינון עבור&nbsp;</h3>}
      <h3>
        {displayedTitle}
      </h3>
    </div>
  );
}

//export default RightType;
