import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./BlueButton.module.scss";

const BlueButton = ({ name, nav, func, className, disabled = false }) => {
  const navigate = useNavigate();

  const clickHandler = async() => {
    let res = undefined;
    res = await func?.();
    console.log(res);

    if(res !== false) {
      navigate(nav);
    }
  }

  return (
    <button className={`${classes["blue-button"]} ${className}`} onClick={clickHandler} disabled={disabled}>
      {name === "סינון" && <img alt="filterImage" src="images/filter.png" />}
      <h4 className={classes["blue-button__h4"]}>{name}</h4>
    </button>
  );
};

export default BlueButton;
