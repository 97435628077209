import { configureStore } from "@reduxjs/toolkit";
import mainSlice from "../slices/mainSlice";
import userRequestsSlice from "../slices/userRequestsSlice";
import appointmentsSlice from "../slices/appointmentsSlice";
import filterSlice from "../slices/filterSlice";
import eventsSlice from "../slices/eventsSlice";
import generalSettingsSlice from '../slices/generalSettings';
import itemsSlice from "../slices/itemsSlice";

export const store = configureStore({
  reducer: {
    mainSlice: mainSlice,
    userRequestsSlice: userRequestsSlice,
    appointmentsSlice: appointmentsSlice,
    filterSlice: filterSlice,
    eventsSlice, eventsSlice,
    generalSettingsSlice, generalSettingsSlice,
    itemsSlice, itemsSlice,
  },
});

export const AppDispatch = typeof store.dispatch;
export const RootState = typeof store.getState;
