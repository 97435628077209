import LargeContainer from "../../components/LargeContainer/LargeContainer";
import "./RequestsList.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  // GotBaseUnits,
  // GotRequestTypesToUnit,
  // SetServiceUnit,
  SetSelectedRequest,
  SetSelectedRequestType,
} from "../../slices/mainSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import dayjs from "dayjs";

//many dev data is not in the date range, so...
// TRUE: data is filtered by date.
// FALSE: all data is shown.
const DEV__FILTER_BY_DATE = false;

/**
 * 
 * @param {{reqDate : string, codRequestType : string}} item 
 * @returns The text to display on the date part of the item
 */
const requestItemDateFormat = (item) => {

  const reqDate = dayjs(item.requestDate)

  switch (item.codRequestType) {
    case 2: // need to be an enviromental constant
    case 4: // same goes here
      if(item.requestDate === '') return 'לא נבחר תאריך';
      return reqDate.format('DD/MM/YYYY');
    default:
      return reqDate.format('DD/MM/YYYY HH:mm');
  }

}

export default function RequestsList({title, requestList = [], emptyListComment, modifyState }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const {title, date, session, notes} = booking;
  const [currSelectedRequest, setSelectedRequest] = useState(0);
  // const codRequest = searchParams.get("codRequest") || "";
  // const modifyState = searchParams.get("modifyState") || "";
  useEffect(() => {
    if (currSelectedRequest !== 0) {
      dispatch(SetSelectedRequest(currSelectedRequest));
      console.log(currSelectedRequest)
      console.log(requestList)
      console.log(requestList.filter(
        (itm) => itm.codRequest === currSelectedRequest
      ))
      const requstType = requestList.filter(
        (itm) => itm.codRequest === currSelectedRequest
      )[0].codRequestType;
      dispatch(SetSelectedRequestType(requstType));

      navigate(
        requstType === 2
          ? `/Request/Qualification/edit?modifyState=${modifyState}`
          : requstType === 1
          ? `/Request/Logistic/edit?modifyState=${modifyState}`
          : `/Request/Maintenance/edit?modifyState=${modifyState}`
      );
    }
  }, [currSelectedRequest, navigate]);

  // all the request displayed filtered and sorted
  const DisplayedRequests = requestList?.filter(req => (
    !DEV__FILTER_BY_DATE
    ||
    req.requestDate === '' || dayjs(req.requestDate)
    .isAfter(dayjs().hour(0).minute(0).second(0).millisecond(0))
    )).sort(
      (a,b) => (
        (new Date(a.requestDate)).getTime() - (new Date(b.requestDate)).getTime()
      )
    );

  return (
    <div className="request-div" style={{
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(/images/144_large.jpg)'
    }}>
      <div className="request-div-wrapper">
        <h4>{title}</h4>
        {DisplayedRequests?.length === 0 ?
        <span className="request-div-wrapper__empty">{emptyListComment}</span>
        :
        DisplayedRequests.map((itm, i) => (
          <LargeContainer
            key={i}
            firstTitle={itm.HandleUnit}
            secondTitle={requestItemDateFormat(itm)}
            cod={itm.codRequest}
            navFunc={setSelectedRequest}
          />
        ))}
        {/* <img alt="under-img" src="images/144_large.jpg"></img> */}
      </div>
    </div>
  );
}
