import "./MessageBox.scss";
import PageSpinner from "../UI/PageSpinner";

export default function MessageBox({ message = "", msgType = 1 }) {
  return (
    <div className="msg-content">
      {msgType === "2" && <PageSpinner />}
      <h2>{message}</h2>
    </div>
  );
}
