import { useEffect } from "react";
//import { getBaseUnits, getRequestTypesToUnit } from "./fetchFunctions";
import { useDispatch } from "react-redux";
import { HasError, GettingItems } from "../slices/mainSlice";

export default function useDispachFetches(fetchFunc, dispachFunc, response) {
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("mount");

    if (response.status === "error") dispatch(HasError());
    else if (response.status === "success")
      dispatch(dispachFunc(response.data));
  }, [fetchFunc, dispachFunc, dispatch, response.status, response.data]);
}
