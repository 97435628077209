import { createSlice } from "@reduxjs/toolkit";
import { editItem } from "../data/fetchFunctions";

const initialState = {
  requestsToUser: {},
  loading: true,
  hasError: false,
  errorMsg: "There was a problem fetching data",
};

export const userRequestsSlice = createSlice({
  name: "userRequests",
  initialState,
  reducers: {
    GotRequestsToUser: (state, action) => {
      // I NEED TO FIX IT FROM THE ROOTS.
      if(action.payload === 'Update user was successful') return;
      //console.log(action);
      state.loading = false;
      state.requestsToUser = action.payload;
    },

    SetUserRequest: (state, action) => {
      console.log(state.requestsToUser)
      state.requestsToUser.userRequests.push(action.payload);
    },
    UpdateUserRequest: (state, action) => {
      //state.requestsToUser.userRequests.push(action.payload);
      const reqIndex = state.requestsToUser.userRequests.findIndex(
        (b) => b.codRequest === action.payload.codRequest
      );
      state.requestsToUser.userRequests[reqIndex] = action.payload;
    },

    DeleteUserRequest: (state, action) => {
      
      const reqIndex = state.requestsToUser.userRequests.findIndex(
        (b) => b.codRequest === action.payload.codRequest
      );
        
      console.log(reqIndex);

      state.requestsToUser.userRequests.splice(reqIndex, 1);

    },

    GettingItems: (state) => {
      state.loading = true;
    },
    HasError: (state) => {
      state.loading = false;
      state.hasError = true;
      //state.errorMsg = action.payload;
    },
  },
});

export const {
  GotRequestsToUser,
  SetUserRequest,
  UpdateUserRequest,
  DeleteUserRequest,
  GettingItems,
  HasError,
} = userRequestsSlice.actions;

export default userRequestsSlice.reducer;
